
import { IonPage, IonButton, IonContent, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import okSquare from '../../resources/icons/ok-square.svg';

export default defineComponent({
  name: 'MeasurementSuccess',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonIcon
  },
  setup() {
    const router = useRouter();

    const markAsCompleted = () => {
      router.replace('measurement');
    };

    return {
      okSquare,
      markAsCompleted
    };
  }
});
